import { Button, Stack, Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomCard from "./custom-card";
import { GetQuote } from "./getQuote";
import { Solution } from "./solution";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { Constants } from "../Comman/Constants";

const words = ["Business", "Growth", "Success", "StartUp"];

const Home = () => {
  const [index, setIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState(words[0]);
  const [isRemoving, setIsRemoving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRemoving) {
        setDisplayedText((prev) => prev.slice(0, -1));
        if (displayedText.length === 0) {
          setIsRemoving(false);
          setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }
      } else {
        setDisplayedText((prev) => words[index].slice(0, prev.length + 1));
        if (displayedText === words[index]) {
          setTimeout(() => setIsRemoving(true), 2000);
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [displayedText, isRemoving, index]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleContactClick = () => {
    navigate(paths.contactUs);
  }

  return (
    <>
      <Stack
        bgcolor={"#d7e7ff"}
        direction={"row"}
        gap={3}
        flexWrap={"wrap"}
        mt={0}
        justifyContent={"space-evenly"}
      >
        <Stack
          width={{ md: "40%", xs: "90%" }}
          justifyContent={"center"}
          my={{ md: 10, xs: 2 }}
          ml={{ md: 4, xs: 0 }}
        >
          <Stack
            bgcolor={"#9EBDF2"}
            borderRadius={20}
            color={"#0a2c58"}
            p={1}
            my={4}
            fontWeight={600}
            width={'fit-content'}
            fontSize={{ xs: 13, md: 15 }}
          >
            . Development | Automation | Research | AI
          </Stack>
          <div style={{ fontSize: "50px", fontWeight: "600" }}>
            Digital Solutions for
            <div>
              <span>
                your <u> {displayedText}</u>
              </span>
            </div>
          </div>
          <Stack color={"grey"} fontSize={18} mt={2}>
            Digitalize your business to reach out customers worldwide with
            trending technologies. CodeHawx will help you to build your product
            in latest technologies React, Angular, React Native, .NET, SQL Server, PostgreSQL, MySQL, Clouds.
          </Stack>
          <Button
            variant="contained"
            sx={{ mt: 2, width: { md: "30%", xs: "40%" }, '&:hover': { cursor: 'pointer' } }}
            onClick={handleContactClick}
          >
            Let's Talk
          </Button>
        </Stack>
        <Stack
          width={{ md: "50%", xs: "95%" }}
          justifyContent={"center"}
          alignItems={"center"}
          mr={1}
          my={2}
        >
          {" "}
          <object data="/images/home-banner.svg" type="image/svg+xml">
            Your browser does not support SVG.
          </object>
        </Stack>
      </Stack>
      <Stack justifyContent={"center"} alignItems={"center"} my={5}>
        <Stack
          fontSize={{ md: 45, xs: 40 }}
          fontWeight={{ md: 700, xs: 600 }}
          color={"#333333"}
          width={{ md: "60%", xs: "90%" }}
          textAlign={"center"}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          Using technology to boost{" "}
        </Stack>
        <Stack
          fontSize={{ md: 45, xs: 40 }}
          fontWeight={{ md: 700, xs: 600 }}
          color={"#333333"}
          width={{ md: "35%", xs: "90%" }}
          textAlign={"center"}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          business success
        </Stack>
        <Stack mt={4} textAlign={"center"} mx={1} fontFamily={Constants.fontFamilyJosefinSans}>
          A top IT firm committed to giving you an expert service and solutions
          that enables your organization to succeed.
        </Stack>
        <Stack color={"#0a2c58"} fontSize={20} mt={5} fontFamily={Constants.fontFamilyJosefinSans}>
          Want to know more?
        </Stack>
        <Stack fontSize={20} textAlign={"center"} fontFamily={Constants.fontFamilyJosefinSans}>
          Let us take you to a brief tour of what we do.
        </Stack>
        <Stack mt={2} width={{ md: "60%", xs: "90%" }} fontFamily={Constants.fontFamilyJosefinSans}>
          <object data="/images/about-image1.svg" type="image/svg+xml">
            Your browser does not support SVG.
          </object>
        </Stack>
        <Stack mt={5}>
          <CustomCard />
        </Stack>
        <Box
          sx={{
            textAlign: "center",
            py: 4,
            position: "relative",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "rgba(0, 0, 0, 0.05)",
              fontWeight: 900,
              fontSize: { xs: "2.5rem", md: "5rem" },
              zIndex: 0,
            }}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            PORTFOLIO
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              zIndex: 1,
              position: "relative",
              fontSize: { xs: "1.5rem", md: "3.3rem" },
              color: "#000",
              bottom: { md: 40, xs: 20 },
            }}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            Explore Our Work
          </Typography>
        </Box>

        <Stack direction={"row"} width={"100%"} flexWrap={"wrap"}>
          <Stack
            sx={{ bgcolor: "rgba(25, 74, 137, 0.3)" }}
            width={isMobile ? "100%" : "33.33%"}
          >
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={25}
              mt={4}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Bharat Sales Corporate
            </Stack>
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={20}
              mt={2}
              mx={2}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
             Design for Plastic Accessories
            </Stack>
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={20}
              mt={2}
              fontWeight={600}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              UI-UX . WEB 
            </Stack>
               <Stack mt={3} justifyContent={"center"} alignItems={"center"} >
              <img src="/images/bsp.png" alt="" width={"60%"}  height={500}  style={{ borderRadius: "25px", border:'2px solid grey' }}/>
            </Stack>
          </Stack>
          <Stack
            sx={{ background: "rgba(25, 74, 137, 0.6)" }}
            width={isMobile ? "100%" : "33.33%"}
          >
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={25}
              mt={4}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Jaivik
            </Stack>
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={20}
              mt={2}
              mx={2}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Design for Steel Accessories Website
            </Stack>
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={20}
              mt={2}
              fontWeight={600}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              UI-UX . WEB 
            </Stack>
            <Stack mt={7} justifyContent={"end"} alignItems={"end"} >
              <img src="/images/jaivik.jpeg" alt="" width={"80%"}  height={350}  style={{ borderRadius: "10px" }}/>
            </Stack>
          </Stack>
          <Stack
            sx={{ bgcolor: "#194a89" }}
            width={isMobile ? "100%" : "33.33%"}
          >
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={25}
              mt={4}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Marketing & Branding
            </Stack>
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={20}
              mt={2}
              mx={2}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Digital branding and comprehensive neuromarketing services.
            </Stack>
            <Stack
              color={"white"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={20}
              mt={2}
              fontWeight={600}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              UI-UX . WEB . MOBILE
            </Stack>
            <Stack mt={3} justifyContent={"end"} alignItems={"center"}>
              <img src="/images/work3.svg" alt="" width={"100%"} />
            </Stack>
          </Stack>
        </Stack>

        <Solution />
      </Stack>

      <GetQuote />
    </>
  );
};
export { Home };
