// ProjectCard.tsx
import React from 'react';
import { Constants } from "../Comman/Constants";

const ProjectCard = ({ title, image, description, tags }) => {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s',
      }}

    >
      <h3
        style={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: '#1e3a8a',
          margin: '0',
          fontFamily: Constants.fontFamilyJosefinSans
        }}
      >
        {title}
      </h3>
      <img
        src={image}
        alt={title}
        style={{
          width: '100%',
          height: '300px',
          borderRadius: '8px',
          marginTop: '10px',
        }}
      />
      <p
        style={{
          fontSize: '1.3rem',
          color: '#4a4a4a',
          margin: '10px 0',
          fontFamily: Constants.fontFamilyJosefinSans
        }}
      >
        {description}
      </p>
      <div style={{ marginTop: '10px' }}>
        {tags.map((tag, index) => (
          <span
            key={index}
            style={{
              fontSize: '1rem',
              color: '#555',
              marginRight: '10px',
              fontFamily: Constants.fontFamilyJosefinSans
            }}
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export { ProjectCard };
